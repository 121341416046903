

import {NotFoundPage} from "../Components/NotFoundPage";
import MainPageAsync from "../Components/MainPage/MainPageAsync";
import CarsPageAsync from "../Components/CarsPage/CarsPageAsync";
import OrderPageAsync from "../Components/OrderPage/OrderPageAsync";
import CarDetailsPage from "../Components/CarDetailsPage/CarDetailsPage";
import CarDetailsPageAsync from "../Components/CarDetailsPage/CarDetailsPageAsync";
import LoginPageAsync from "../Components/LoginPage/LoginPageAsync";
import UserPageAsync from "../Components/UserPage/UserPageAsync";
import RegistrationPageAsync from "../Components/RegistrationPage/RegistrationPageAsync";
import RegistrationSuccessPage
    from "../Components/RegistrationPage/RegistrationForms/RegistrationSuccessPage/RegistrationSuccessPage";
import EditUserPageAsync from "../Components/EditUserPage/EditUserPageAsync";
import EditCarPageAsync from "../Components/EditCarPage/EditCarPageAsync";
import ContactsPage from "../Components/ContactsPage/ContactsPage";
import DocumentationPage from "../Components/DocumentationPage/DocumentationPage";
import DocumentationPageAsync from "../Components/DocumentationPage/DocumentationPageAsync";


// let { id } = useParams();
export const RoutePaths = {
    main: '/',
    cars: '/cars',
    carsId: '/cars/:id',
    carEdit:  `cars/:id/edit`,
    order: '/order',
    contacts: '/contacts',
    login: '/login',
    registration: '/registration',
    registrationSuccess: 'registration/success',
    user: '/user',
    userEdit:  `users/:id/edit`,
    documentation: `documentation`,
    not_found: '*',
};


export const routeConfig = {
    main: {
        path: RoutePaths.main,
        element: <MainPageAsync />,
    },
    cars: {
        path: RoutePaths.cars,
        element: <CarsPageAsync />,
    },
    carsId: {
        path: RoutePaths.carsId,
        element: <CarDetailsPageAsync />,
    },
    carEdit: {
        path: RoutePaths.carEdit,
        element: <EditCarPageAsync />,
    },
    userEdit: {
        path: RoutePaths.userEdit,
        element: <EditUserPageAsync />,
    },
    order: {
        path: RoutePaths.order,
        element: <OrderPageAsync />,
    },
    login: {
        path: RoutePaths.login,
        element: <LoginPageAsync />,
    },
    registration: {
        path: RoutePaths.registration,
        element: <RegistrationPageAsync />,
    },
    registrationSuccess: {
        path: RoutePaths.registrationSuccess,
        element: <RegistrationSuccessPage />,
    },
    user: {
        path: RoutePaths.user,
        element: <UserPageAsync />,
    },
    user_cars: {
        path: RoutePaths.user,
        element: <UserPageAsync />,
    },
    contacts: {
        path: RoutePaths.contacts,
        element: <ContactsPage />,
    },
    documentation:{
        path: RoutePaths.documentation,
        element: <DocumentationPageAsync />,
    },
    not_found: {
        path: RoutePaths.not_found,
        element: <NotFoundPage />,
    },
};
