
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios";
import {userActions} from "../userSlice";
import {apiUrl} from "../../../shared/API/API";

export const fetchUser = createAsyncThunk(
    'user/fetchUser',
    async (id, thunkAPI) => {
        try {
            const response = await axios.get(`${apiUrl}/${id}`)
            if(!response.data) throw new Error();
            thunkAPI.dispatch(userActions.setUserData(response.data))
            return response.data
        }catch (e) {
            console.log(e);
            return thunkAPI.rejectWithValue('Ошибка на сервере при логине')
        }
    }
)
