
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios";
import {apiUrl} from "../../../shared/API/API";
import {userActions} from "../../../UserPage/model/userSlice";
import {loginActions} from "../registrationSlice";
import {USER_LOCALSTORAGE_KEY} from "../../../shared/const/localstorage";

export const registrationUserThunk = createAsyncThunk(
    'user/registration',
    async (data, thunkAPI) => {
        try {
            const response = await axios.post(`${apiUrl}/registration`, data)
            if(response?.data?.status !== 'OK') throw new Error('Ошибка на сервере при регистрации пользователя');

            const user = response.data.data;
            localStorage.setItem(USER_LOCALSTORAGE_KEY, JSON.stringify(user))
            thunkAPI.dispatch(userActions.setUserData(user))
            return user
        }catch (e) {
            console.log(e);
            let msg = e?.response?.data?.message
            return thunkAPI.rejectWithValue(`Ошибка на сервере! Возможно ваш email уже занят!`)
        }
    }
)
