
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios";
import {apiUrl} from "../../../shared/API/API";
import {userActions} from "../../../UserPage/model/userSlice";
import {loginActions} from "../loginSlice";
import {USER_LOCALSTORAGE_KEY} from "../../../shared/const/localstorage";

export const loginUserThunk = createAsyncThunk(
    'user/fetchUser',
    async (data, thunkAPI) => {
        try {
            const response = await axios.post(`${apiUrl}/login`, data)
            if(response?.data?.status !== 'OK') throw new Error(`Вы ввели неверный логин или пароль`);
            const user = response.data.data;
            localStorage.setItem(USER_LOCALSTORAGE_KEY, JSON.stringify(user))
            return user
        }catch (e) {
            console.log(e);
            let msg = e?.response?.data?.message
            return thunkAPI.rejectWithValue(`Вы ввели неверный логин или пароль`)
        }
    }
)
