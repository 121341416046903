import { GoogleMap, Marker, useLoadScript,   DirectionsService,
    DirectionsRenderer, StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";

export const fetchDirection = async ( setDirectionsResponse, locations, setError) => {
    const directionsService = new window.google.maps.DirectionsService();
    try{
        const results = await directionsService.route({
            origin: locations.origin,
            destination: locations.destination,
            travelMode: "DRIVING",
        })
        if(results?.status === 'OK') setDirectionsResponse(results)
        else setError('Маршрут времменно не доступен');
    }catch (e) {
        setError('Маршрут времменно не доступен');
    }

}





