
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios";
import {orderFormActions} from "../orderFormSlice";
import {apiUrl} from "../../../../shared/API/API";

export const sendCarForm = createAsyncThunk(
    'cars/fetchCars',
    async (formData, thunkAPI) => {
        try {
            console.log(formData)
            const response = await axios.post(`${apiUrl}/cars`, formData,
                {
                    headers: {
                        // Overwrite Axios's automatically set Content-Type
                        "Content-type": "application/json; charset=UTF-8"
                    }
                }
            )
            console.log(response.data)
            if(response?.data?.status !== 'OK') throw new Error('Ошибка на сервере при получении информации');
            thunkAPI.dispatch(orderFormActions.setMessage(`'Ваша заявка успешно зарегестрирована!'`))
            return response.data.data

            // if(!response.data) throw new Error();
            // thunkAPI.dispatch(orderFormActions.setMessage(response.data.message))
            // return response.data
        }catch (e) {
            console.log(e);
            console.log(e?.response?.data)
            thunkAPI.dispatch(orderFormActions.setMessage('Ошибка на сервере при отправке формы'))
            return thunkAPI.rejectWithValue('Ошибка на сервере при отправке формы')
        }
    }
)
