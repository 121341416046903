import React from 'react';
import cls from './ErrorPage.module.scss';
import Button from "../Button/Button";



export const ErrorPage = ({ className }) => {

    const reloadPage = () => {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
    };

    return (
        <div className={cls.ErrorPage}>
            <p>Произошла непредвиденная ошибка</p>
            <Button onClick={reloadPage} classes={['btn_info']}>
                Обновить страницу
            </Button>
        </div>
    );
};
