
import React, {useState} from 'react';
import cls from './DocumentationPage.module.scss'
import { Divider } from 'antd';

import {useTranslation} from "react-i18next";
import { Document, Page, pdfjs } from 'react-pdf';
import LicensePDF from '../../images/License.pdf';
import PermissionPDF from '../../images/Permission.pdf';
// import "react-pdf/dist/esm/Page/TextLayer.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
// /images/License.pdf
// import delivery_scheme_3 from '../../../images/delivery_scheme_3.png'


const DocumentationPage = () => {
    const { t } = useTranslation('DocumentationPage');

    return (
        <div className={cls.documentation}>
            <div className={cls.container}>

                <div className={cls.title}>
                    <h1>{t('Работаем на рынке перевозок автомобилей с 2018 года')}</h1>
                    <Divider/>
                    <div className={cls.title__description}>
                        {t('Доставка автомобиля по максимально комфортным условиям')}
                    </div>
                </div>

                <div className={cls.licenses}>
                    <div className={cls.file}>
                        <h2>{t('Лицензия на перевозку грузов')}</h2>
                        <div>
                            <Document file={LicensePDF} >
                                <Page scale={0.5} pageNumber={1} renderAnnotationLayer={false} renderTextLayer={false}/>
                            </Document>
                        </div>
                    </div>
                    <div className={cls.file}>
                        <h2>{t('Разрешение на перевозку грузов')}</h2>
                        <div className={cls.file__pdf}>
                            <Document file={PermissionPDF}  >
                                <Page scale={0.5} pageNumber={1} renderAnnotationLayer={false} renderTextLayer={false}/>
                            </Document>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    );
};

export default DocumentationPage;
