import React from 'react';
import cls from "./ForPartners.module.scss";
import car_delivery_1 from '../../../images/car_delivery_1.png';

import {AppLink} from "../../../router/AppLink";
import {btnTypesColors} from "../../shared/Types/btnTypesColors";
import {Button} from "antd";
import {useTranslation} from "react-i18next";




const ForPartners = () => {
    const { t } = useTranslation('MainPage');
    return (
        <section className={cls.documentation}>
            <div className={cls.container}>
                <div className={cls.cart}>
                    <div className={cls.cart__info}>
                        <div>
                            <div className={cls.cart__title}>{t('Вы перевозчик ?')}</div>
                            <div className={cls.cart__description}>
                                {t('Зарегестрируйтесь, чтобы получить доступ ко всем опубликованным объявлениям')}
                            </div>

                        </div>
                        <div>
                            <AppLink to={`/registration`}>
                                <Button type={`primary`}
                                        className={cls.btn}
                                        style={{ background: btnTypesColors[`dark_blue`],
                                            color: 'white',
                                            fontWeight: '500',
                                            padding: `20px`,
                                            lineHeight: `2px`,
                                            textTransform: `uppercase`,
                                        }}
                                >
                                    {t('Зарегистрироваться')}
                                </Button>
                            </AppLink>
                        </div>

                    </div>


                    <div>
                        <div className={cls.cart__background}></div>
                        <div className={cls.cart__img}>
                            {/*<img src={car_delivery_1} alt="car delivery"/>*/}
                        </div>

                    </div>

                </div>
            </div>

        </section>
    );
};

export default ForPartners;
