import React from 'react';
import cls from './UserPage.module.scss'
import {useSelector} from "react-redux";
import CustomerRolePageAsync from "./RolePages/CustomerRolePage/CustomerRolePageAsync";
import CarrierRolePageAsync from "./RolePages/CarrierRolePage/CarrierRolePageAsync";
import {Text} from "../shared/Text/Text";
import AdminRolePageAsync from "./RolePages/AdminRolePage/AdminRolePageAsync";
import {userSlice} from "./model/userSlice";



export const userRoles = {
    CUSTOMER: 'customer',
    CARRIER: 'carrier',
    ADMIN: 'admin'
}
const userRolePage = {
    customer: <CustomerRolePageAsync />,
    carrier: <CarrierRolePageAsync />,
    admin: <AdminRolePageAsync />,
}
export const contentPagesTypes = {
    cars: 'cars',
    deliveries: 'deliveries',
    orders: 'orders',
    info: 'info',
    notifications: 'notifications',
    users: `users`
}

const UserPage = () => {
    const {user} = useSelector(store => store.user);

    return (
        <div className={cls.userPage}>

            {user  && userRolePage[user.role]}
            {!user  &&
                <div>
                    <Text text={`Пожалуйста зарегестрируйтесь или ввойдите в свою учетную запись`}
                    />
                </div>
            }
        </div>
    );
};

export default UserPage;
