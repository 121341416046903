
import {lazy} from 'react';

// const OrderPageAsync = lazy( ()=> new Promise((resolve) =>{
//     setTimeout( ()=> resolve(import('./OrderPage')), 500)
//     }
// ))

const DocumentationPageAsync = lazy( ()=> import('./DocumentationPage'))

export default DocumentationPageAsync







