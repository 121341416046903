
export const CarStatus = {
    CREATED: "created",
    ACTIVE: "active",
    BOOKED: "booked",
    COMPLETED: "completed",
    REJECTED: "rejected",
    DELETED: "deleted",
    DONE: "done"
}
//     created: 'created',
//     active: 'active',
//     booked: 'booked',
//     done: 'done',
//     rejected: 'rejected',
