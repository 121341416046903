import { createSlice } from '@reduxjs/toolkit'
import {fetchCars} from "../../../CarsPage/model/services/getCarsFromServer";
import {sendCarForm} from "./services/sendCarFromOnServer";
import {useId} from "react";


const initialState = {
    form: {
        downloading_address: '',
        unloading_address: '',
        downloading_data: '',
        car_brand: '',
        car_model: '',
        car_condition: '',
        car_image: '',
        car_delivery_price: '',
        additional_information: '',
        express_delivery: false,
        locations: {downloading_address: {lat: 0, lng: 0}, unloading_address: {lat: 0, lng: 0} },
        createdBy: null,

    },
    currentPageNumber: 0,
    error: false,
    isLoading: true,
    message: '',
}

export const orderFormSlice = createSlice({
    name: 'orderForm',
    initialState,
    reducers: {
        changeField: (state, action) => {
            let {fieldName, fieldData} = action.payload
            state.form[fieldName] = fieldData;
        },
        setLocation: (state, action) => {
            // console.log(action.payload)
            let {fieldName, coordinates} = action.payload
            state.form.locations[fieldName] = coordinates;
        },
        resetForm: (state) => {
            state.form = initialState.form;
            state.currentPageNumber = 0;
        },
        changeCurrentPageNumber: (state, action)=>{
            state.currentPageNumber = action.payload
        },
        setMessage: (state, action)=>{
            console.log(action.payload)
            state.message = action.payload;
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(sendCarForm.pending, (state, action) => {
                state.error = undefined;
                state.isLoading = true;
            })
            .addCase(sendCarForm.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(sendCarForm.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
    },
})

export const { actions: orderFormActions } = orderFormSlice
export const { reducer: orderFormReducer } = orderFormSlice

