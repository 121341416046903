
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios";
import {apiUrl} from "../../../../../shared/API/API";


export const getNotifications = createAsyncThunk(
    'notifications/getNotifications',
    async (data, thunkAPI) => {
        try {
            let {params={}} = data;
            const response = await axios.get(`${apiUrl}/notifications`, {params})
            if(response?.data?.status !== 'OK') throw new Error('Ошибка на сервере при получении уведомлений');
            return response.data.data
        }catch (e) {
            console.log(e);
            return thunkAPI.rejectWithValue('Ошибка на сервере при получении уведомлений')
        }
    }
)
