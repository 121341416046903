
import {lazy} from 'react';

// const OrderPageAsync = lazy( ()=> new Promise((resolve) =>{
//     setTimeout( ()=> resolve(import('./OrderPage')), 500)
//     }
// ))

const OrderPageAsync = lazy( ()=> import('./OrderPage'))

export default OrderPageAsync







