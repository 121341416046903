import React, {useEffect, useState} from 'react';
import {LANGUAGES} from "../../../constants/languages";
import { Select, Space } from 'antd';
import cls from './LangSwitcher.module.scss'
import {useTranslation} from "react-i18next";
import useLocalStorage from "../lib/useLocalStorage";


const LangSwitcher = () => {

    const { t, i18n } = useTranslation();

    const defaultLanguage = 'de';
    const [language, setLanguage] = useLocalStorage(LOCAL_STORAGE_LANGUAGE_KEY, defaultLanguage);

    const changeLanguage = (value) => {
        i18n.changeLanguage(value);
        setLanguage(value)
    };
    useEffect(()=>{i18n.changeLanguage(language);}, [])

    return (
        <div className={cls.container}>
            <Space wrap>
            <Select defaultValue={language}
                    options={
                        LANGUAGES.map(({ code, label }) => (
                             {value: code, label: code,}
                        ))
                    }
                    // onChange={changeLanguage}
                    onChange={changeLanguage}
            >
            </Select>
            </Space>
        </div>
    );
};

export default LangSwitcher;
export const LOCAL_STORAGE_LANGUAGE_KEY = 'language';
