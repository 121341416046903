
import { createSlice } from '@reduxjs/toolkit'
import {getCar} from "./services/getCarFromServer";


const initialState = {
    car: {
        id: 0,
        downloading_address: '',
        unloading_address: '',
        downloading_data: '',
        car_brand: '',
        car_model: '',
        car_condition: '',
        car_image: '',
        car_delivery_price: '',
        additional_information: '',
        express_delivery: false,
        status: '',
        createdAt: 1697460097643,
    },
    error: false,
    isLoading: true,
    message: '',
}

export const carDetailsSlice = createSlice({
    name: 'carDetails',
    initialState,
    reducers: {

        setCar: (state, action)=>{
            // console.log(action.payload)
            state.car = action.payload
            // console.log(state.car)
        },
        setMessage: (state, action)=>{
            // console.log(action.payload)
            state.message = action.payload;
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(getCar.pending, (state, action) => {
                state.error = undefined;
                state.isLoading = true;
            })
            .addCase(getCar.fulfilled, (state, action) => {
                state.isLoading = false;
                state.car = action.payload
            })
            .addCase(getCar.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
    },
})

export const { actions: carDetailsActions } = carDetailsSlice
export const { reducer: carDetailsReducer } = carDetailsSlice

