
import {lazy} from 'react';

// const OrderPageAsync = lazy( ()=> new Promise((resolve) =>{
//     setTimeout( ()=> resolve(import('./OrderPage')), 500)
//     }
// ))

const CustomerRolePageAsync = lazy( ()=> import('./CustomerRolePage'))

export default CustomerRolePageAsync







