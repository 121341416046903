import { createSlice } from '@reduxjs/toolkit'

export const SearchCarsFormOptions = {
        sortByDate:{
            'desc': (a, b)=> b.createdAt - a.createdAt,
            'asc': (a, b)=> a.createdAt - b.createdAt,
        },
        sortByPrice:{
            'desc': (a, b)=> Number(b.car_delivery_price) - Number(a.car_delivery_price),
            'asc': (a, b)=>  Number(a.car_delivery_price) - Number(b.car_delivery_price),
        },
        filterByAddress: (text, str) => text.toLowerCase().includes(str.trim().toLowerCase()),
}

const initialState = {
    sortByDateOrder: 'desc', // asc
    sortByPriceOrder: '', //desc asc

    downloading_address: '',
    unloading_address: '',
}

export const searchCarsFormSlice = createSlice({
    name: 'searchForm',
    initialState,
    reducers: {
        changeField: (state, action)=>{
            const {field, value} = action.payload;
            state[field] = value;
        },
        resetSortByFields: (state)=>{
            state.sortByDateOrder = '';
            state.sortByPriceOrder = '';
        },
        incrementByAmount: (state, action) => {
            state.value += action.payload
        },
    },
})

export const { actions: searchCarsFormActions } = searchCarsFormSlice
export const { reducer: searchCarsFormReducer } = searchCarsFormSlice
