
import React from 'react';
import cls from './ContactsPage.module.scss'
import { Divider } from 'antd';
import {ClockCircleOutlined, FacebookOutlined, WhatsAppOutlined, EnvironmentOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";


const ContactsPage = () => {
    const { t } = useTranslation('ContactsPage');

    return (
        <div className={cls.contacts}>
            <div className={cls.container}>

                <div className={cls.title}>
                    <h1>{t('Контакты')}</h1>
                    <div className={cls.title__description}>
                        {t('Если у вас есть какие-либо вопросы, предложения, идеи, или вам просто что-то не до конца понятно – обязательно свяжитесь с нами')}
                    </div>
                </div>


                <div className={cls.address}>
                    <div className={cls.address__city}>
                        <h2>{t('Польша')}</h2>
                    </div>
                    <Divider />

                    <div  className={cls.office}>
                        <div  className={cls.office__address}>
                            <div>{t('Офис')}:</div>
                            <div>
                                <EnvironmentOutlined  style={{margin: `10px 10px 0px 0px`}}/>
                                {t('Раковицка 10б/4, Краков, Польша')}
                            </div>
                        </div>
                        <div  className={cls.office__time}>
                            <div>
                                {t('Время работы')}:
                            </div>
                            <div>
                                <ClockCircleOutlined  style={{margin: `10px 10px 0px 0px`}}/>
                                {t('7 дней в неделю, с 10.00 до 22.00')}</div>
                        </div>
                    </div>

                </div>


                <div className={cls.info}>
                    <h2>{t('Связаться')}</h2>
                    <Divider />

                    <div>
                        <ul className= {cls.info__list}>
                            <li>
                                <span  className= {cls.phone_mark}>📞</span>+49 177 599 32 33
                            </li>
                            <li>
                                <span  className= {cls.phone_mark}><WhatsAppOutlined /></span> +49 177 599 32 33
                            </li>
                            <li>
                                <span  className= {cls.phone_mark}><WhatsAppOutlined /></span> +48 796 514 268
                            </li>
                            <li>
                                <span  className= {cls.email_mark}>📨</span>kadispzoo@gmail.com
                            </li>
                            <li>
                                <a href="https://www.facebook.com/Kaditrans" target="_blank">
                                    <span  className= {cls.facebook_mark}><FacebookOutlined /></span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default ContactsPage;
