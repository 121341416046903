import React, {useEffect, useMemo, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";

import { GoogleMap, Marker, useLoadScript,   DirectionsService,
    DirectionsRenderer, StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
import {googleMapJsApiLoaderActions} from "./model/GoogleMapJsApiLoaderSlice";

const GoogleMapJsApiLoader = ({children}) => {

    const dispatch = useDispatch();
    const libraries = useMemo(() => ['places'], [] );
    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: 'AIzaSyB7j5FHkAryQhCy1YG3Wr-i8PpzHh6weFY',
        libraries
    });
    useEffect(()=>{
        dispatch(googleMapJsApiLoaderActions.setMapApiIsLoaded(isLoaded))
    }, [isLoaded])

    return (
        <>
            {children}
        </>
    );
};

export default GoogleMapJsApiLoader;
