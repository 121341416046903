import React from 'react';
import {Provider} from "react-redux";
import {createReduxStore} from "./store";


const StoreProvider = ({children, initialState}) => {

    const store = createReduxStore(initialState);

    return (
        <Provider store={store}>
            {children}
        </Provider>
    );
};

export default StoreProvider;
