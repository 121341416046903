import React, { useState } from 'react';
import { Button, Modal } from 'antd';

const btnTypesColors = {
    primary: '#007bff',
    warning: `#ffc107`,
    danger: `#dc3545`,
    info: `#17a2b8`,
    success: '#28a745',
    dark: `#343a40`,
    dark_blue: `#0052B4`,

}

const ModalAsyncForm = (props) => {
    let {btnText, title, content, callback, refresh,
        successMessage, errorMessage, btnType
    } = props;

    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [modalText, setModalText] = useState(content);
    const [requestWasSent, setRequestWasSent] = useState(false);

    const showModal = () => {
        setOpen(true)
        setRequestWasSent(false)
        setModalText(content);
    };

    const handleOk = async () => {
        if(requestWasSent) {
            setOpen(false)
            refresh();
            return;
        };
        setModalText('Запрос на сервер...');
        setConfirmLoading(true);

        let request = await callback();
        console.log(request?.payload)
        if(request.payload.status === 'OK'){
            setModalText(successMessage);
        }else {
            setModalText(`${errorMessage} ${request?.payload}`);
        }
        setRequestWasSent(true)
        setConfirmLoading(false)
    };
    const handleCancel = () => setOpen(false);
    return (
        <>
            <Button type={btnType}
                    onClick={showModal}
                    style={{ background: btnTypesColors[btnType], color: 'white' }}
            >{btnText}
            </Button>
            <Modal
                title={title}
                open={open}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >
                <p>{modalText}</p>
            </Modal>
        </>
    );
};

export default ModalAsyncForm;
