import React, {useEffect, useState, useMemo} from 'react';
import cls from './MapComponent.module.scss'
import { GoogleMap, Marker, useLoadScript,   DirectionsService,
    DirectionsRenderer, StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
import {fetchDirection} from "./lib/fetchDirection/fetchDirection";
import {Text, TextTheme} from "../Text/Text";
import {PageLoader} from "../PageLoader/PageLoader";
import {useSelector} from "react-redux";




const MapComponent = ({locations}) => {

    const {mapApiIsLoaded} = useSelector((state)=> state.googleMapJsApiLoader)
    const [directionsResponse, setDirectionsResponse] = useState(null);
    let [error, setError] = useState(null)

    useEffect(()=>{
        setTimeout(()=> fetchDirection(setDirectionsResponse, locations, setError).catch(console.log) ,200)
    }, [])

    // console.log(locations)
    return (
        <div className={cls.container}>
            {error && <Text text={error} title={'Ошибка на сервере'} theme={TextTheme.ERROR}/> }

            {!mapApiIsLoaded
                ?
                <PageLoader />
                :
                <GoogleMap
                    mapContainerClassName={cls.map} zoom={4}>
                    {directionsResponse && (
                        <DirectionsRenderer directions={directionsResponse} />
                    )}
                </GoogleMap>
            }
        </div>
    );
};

export default MapComponent;

// import {MarkerF} from '@react-google-maps/api'
// import { useMemo } from "react";
// import {carMarker} from "./lib/customMarkers/carMarker/carMarker";

// const center = useMemo(() => ({ lat: 49, lng: 10 }), []);
// const markers = [
//     { lat: 41.54, lng: 12.30 },
//     { lat: 48.08, lng: 11.34 },
// ];
// const onLoad = (map) => {
//     const bounds = new window.google.maps.LatLngBounds();
//     markers?.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));
//     map.fitBounds(bounds);
// };
// {!directionsResponse && (
//     <>
//         <MarkerF position={ { lat: 48.08, lng: 11.34 } } icon={carMarker}/>
//         <MarkerF position={ { lat: 45.28, lng: 9.10 } } />
//     </>
// )}




