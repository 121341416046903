import { createSlice } from '@reduxjs/toolkit'
import {fetchUser} from "./services/fetchUser";
import {USER_LOCALSTORAGE_KEY} from "../../shared/const/localstorage";
import {fetchUserOrders} from "./services/fetchUserOrders";

const initialState = {
    user: null,
    error: false,
    isLoading: true,
    currentPage: `cars`,
    orders: []
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserData: (state, action)=> {
            state.user = action.payload
        },
        initUserData: (state)=> {
            const user = localStorage.getItem(USER_LOCALSTORAGE_KEY);
            if(user) state.user = JSON.parse(user)
        },
        logout: (state)=>{
            state.user = null;
            localStorage.setItem(USER_LOCALSTORAGE_KEY, JSON.stringify(null))
        },
        setOrdersCarsData: (state, action)=>{
            console.log(action.payload)
            state.orders = action.payload
        },
        setCurrentPage: (state, action)=> {
            console.log(action.payload)
            state.currentPage = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUser.pending, (state, action) => {
                state.error = undefined;
                state.isLoading = true;
            })
            .addCase(fetchUser.fulfilled, (state, action) => {
                state.isLoading = false;
                state.user = action.payload;
                // console.log(action.payload)
            })
            .addCase(fetchUser.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
    },
})

export const { actions: userActions } = userSlice
export const { reducer: userReducer } = userSlice

