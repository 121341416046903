import React, {useEffect, useState} from 'react';
import cls from './header.module.scss';
import {AppLink} from "../../router/AppLink";
import {RoutePaths} from "../../router/routeConfig";
import {Button} from "antd";
import LangSwitcher from "../shared/LangSwitcher/LangSwitcher";
import {useTranslation} from "react-i18next";





const HeaderMenu = ({user, handleLogout, onClose}) => {
    const { t } = useTranslation('header');
    return (
            <>
                <div  className={cls.navigation}>
                    <ul className={cls.items}>
                        <li onClick={onClose}><AppLink to={RoutePaths.main} className={cls.item}>{t('Главная')}</AppLink></li>
                        <li onClick={onClose}><AppLink to={RoutePaths.cars} className={cls.item}>{t('Перевозки авто')}</AppLink></li>
                        <li onClick={onClose}><AppLink to={RoutePaths.order} className={cls.item}>{t('Заказать перевозку')}</AppLink></li>
                        <li onClick={onClose}><AppLink to={RoutePaths.contacts} className={cls.item}>{t('Контакты')}</AppLink></li>
                    </ul>
                </div>

                <div className={cls.login}>
                    {!user &&
                        <ul>
                            <li onClick={onClose}><AppLink to={RoutePaths.login} className={cls.item}>{t('Вход')}</AppLink></li>
                            <li onClick={onClose}><AppLink to={RoutePaths.registration} className={cls.item}>{t('Регистрация')}</AppLink></li>
                        </ul>
                    }
                    { user &&
                        <ul>
                            <li onClick={onClose}><AppLink to={RoutePaths.user} className={cls.item}>{user.name || user.company_name}</AppLink></li>
                            <li onClick={handleLogout}><AppLink to={RoutePaths.login} className={cls.item}>{t('Выйти')}</AppLink></li>
                        </ul>
                    }
                </div>
                <LangSwitcher />


            </>
    );
};

export default HeaderMenu ;
