import React, {Suspense} from 'react';
import './App.css';
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import {AppRouter} from "./router/AppRouter";
import {BrowserRouter} from "react-router-dom";
import StoreProvider from "./StoreProvider/StoreProvider";
import GoogleMapJsApiLoader from "./Components/GoogleMapJsApiLoader/GoogleMapJsApiLoader";
import ErrorBoundary from "./ErrorBoundary/ErrorBoundary";
import SpinLoader from "./Components/shared/SpinLoader/SpinLoader";


function App() {

  return (
      <StoreProvider>
          <BrowserRouter>
              <ErrorBoundary>

                  <div className="App container">
                    <Suspense fallback={<SpinLoader />} >

                        <GoogleMapJsApiLoader>
                            <Header />
                               <div className="content-page">
                                  <AppRouter />
                               </div>
                            <Footer />
                        </GoogleMapJsApiLoader>

                    </Suspense>
                  </div>

              </ErrorBoundary>
          </BrowserRouter>
      </StoreProvider>
  );
}

export default App;
