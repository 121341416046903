
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios";
import {carDetailsActions} from "../carDetailsSlice";
import {apiUrl} from "../../../shared/API/API";

export const getCar = createAsyncThunk(
    'car/fetchCar',
    async ( data, thunkAPI) => {
        try {
            let {id, user} = data;
            let headers = {}

            if(user){
                headers = {
                    id: user._id,
                    password: user.password
                }
            }

            const response = await axios.get(`${apiUrl}/cars/${id}`, {headers})

            if(response?.data?.status !== 'OK') throw new Error('Ошибка на сервере при получении информации');
            return response.data.data
        }catch (e) {
            console.log(e);
            return thunkAPI.rejectWithValue('Ошибка на сервере при получении информации')
        }
    }
)
