import React from 'react';
import {AppLink} from "../../../router/AppLink";
import Button from "../../shared/Button/Button";
import ModalAsyncForm from "../../shared/ModalAsyncForm/ModalAsyncForm";
import {applyForCar} from "./services/applyForCar";
import {useDispatch} from "react-redux";

const ApplyPage = ({car, user, refresh, btnType}) => {
    const dispatch = useDispatch();
    let data = {car, user}
    let callback = async ()=> await dispatch(applyForCar(data))
    return (
        <div>
            <ModalAsyncForm
                btnText={`Оформить заявку на перевозку`}
                title={`Подтвердите заявку`}
                content={
                `Заявка на перевозку ${car.car_brand} ${car.car_model} 
                из ${car.downloading_address} в ${car.unloading_address}.
                Стомость: ${car.car_delivery_price} € 
                `}
                successMessage={`Ваша заявка успешно подтверждена!`}
                errorMessage={`Ошибка на сервере!`}
                callback={callback}
                refresh={refresh}
                btnType={btnType}
            />
        </div>
    );
};

export default ApplyPage;
