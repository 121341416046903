import React from 'react';
import cls from "../CarDetailsPage.module.scss";

const DetailItem = ({title='', infoTitle='', className='', infoData=''}) => {
    return (
        <>
            <div className={cls.detailsTitle}>{title}</div>
            <div className={cls.detailsInfo}>
                <div className={cls.infoTitle}>{infoTitle}</div>
                <div className={cls.infoData}><span className={className}>{infoData}</span></div>
            </div>
        </>
    );
};

export default DetailItem;
