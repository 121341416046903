import React from 'react';
import {AppLink} from "../../../router/AppLink";
import Button from "../../shared/Button/Button";
import ModalAsyncForm from "../../shared/ModalAsyncForm/ModalAsyncForm";
import {applyForCar} from "./services/applyForCar";
import {useDispatch} from "react-redux";
import {refuseCarDelivery} from "./services/refuseCarDelivery";


const RefuseDeliveryPage = ({car, user, refresh, btnType}) => {
    const dispatch = useDispatch();
    let data = {car, user}
    let callback = async ()=> await dispatch(refuseCarDelivery(data))
    return (
        <div>
            <ModalAsyncForm
                btnText={`Отменить заявку`}
                title={`Отменить заявку`}
                content={
                `Отменить заявку на перевозку ${car.car_brand} ${car.car_model} 
                из ${car.downloading_address} в ${car.unloading_address}.
                Стомость: ${car.car_delivery_price} € 
                `}
                successMessage={`Ваша заявка на выполнение работ отклонена!`}
                errorMessage={`Ошибка на сервере!`}
                callback={callback}
                refresh={refresh}
                btnType={btnType}
            />
        </div>
    );
};

export default RefuseDeliveryPage;
