
import {lazy} from 'react';

// const OrderPageAsync = lazy( ()=> new Promise((resolve) =>{
//     setTimeout( ()=> resolve(import('./OrderPage')), 500)
//     }
// ))

const AdminRolePageAsync = lazy( ()=> import('./AdminRolePage'))

export default AdminRolePageAsync







