
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios";
import {apiUrl} from "../../../../../../shared/API/API";


export const getUserDeliveries = createAsyncThunk(
    'user/getUserDeliveries',
    async (data, thunkAPI) => {
        try {
            let {params={}} = data;
            const response = await axios.get(`${apiUrl}/cars`, {params})
            if(response?.data?.status !== 'OK') throw new Error('Ошибка на сервере при полученни автомобилей на доставке');
            return response.data.data
        }catch (e) {
            console.log(e);
            return thunkAPI.rejectWithValue('Ошибка на сервере при полученни автомобилей на доставке')
        }
    }
)
