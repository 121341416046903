
export const LANGUAGES = [
    { label: "German",    code: 'de' },
    { label: "English",   code: "en" },
    { label: "Russian",   code: 'ru' },
    { label: "Poland",    code: 'pl' },
    { label: "Ukrainian", code: 'uk' },
    { label: "Spanish",   code: "es" },
    { label: "Italian",   code: "it" },
];

