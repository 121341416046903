import { createSlice } from '@reduxjs/toolkit'
import {getUserCarsFromServer} from "./services/getUserCarsFromServer";


const initialState = {
    error: false,
    isLoading: true,
    orders: [],
    cars: []
}

export const userCarsSlice = createSlice({
    name: 'userCars',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserCarsFromServer.pending, (state, action) => {
                state.error = undefined;
                state.isLoading = true;
            })
            .addCase(getUserCarsFromServer.fulfilled, (state, action) => {
                state.isLoading = false;
                state.orders = action.payload;
            })
            .addCase(getUserCarsFromServer.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
    },
})

export const { actions: userCarsActions } = userCarsSlice
export const { reducer: userCarsReducer } = userCarsSlice

