import { createSlice } from '@reduxjs/toolkit'
import {loginUserThunk} from "./services/LoginUserThunk";

const initialState = {
    error: false,
    isLoading: false,
    message: '',
}

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(loginUserThunk.pending, (state, action) => {
                state.error = undefined;
                state.isLoading = true;
                state.message = '';
            })
            .addCase(loginUserThunk.fulfilled, (state, action) => {
                state.isLoading = false;
                // console.log(action.payload)
                // state.user = action.payload;
            })
            .addCase(loginUserThunk.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
    },
})

export const { actions: loginActions } = loginSlice
export const { reducer: loginReducer } = loginSlice

