import { createSlice } from '@reduxjs/toolkit'
import {registrationUserThunk} from "./services/RegistrationUserThunk";
import {userRoles} from "../../UserPage/UserPage";

const customerForm = {
    "role": "customer",
    "name": "",
    "email": "",
    "phone": "",
    "password": "",
}

const carrierForm = {
    "role": "carrier",
    "company_name": "",
    "email": "",
    "phone": "",
    "country": "",
    "vat_number": "",
    "password": ""
}

const initialState = {
    registrationForms:{
        [userRoles.CUSTOMER]: customerForm,
        [userRoles.CARRIER]: carrierForm
    },
    error: false,
    isLoading: false,
    message: '',
    userRole: userRoles.CUSTOMER,
}

export const registrationSlice = createSlice({
    name: 'registration',
    initialState,
    reducers: {
        setUserRole: (state, action)=>{
            state.userRole = action.payload;
        },
        setRegistrationFormsField: (state, action)=>{
            let {userRole, field, value} = action.payload;
            state.registrationForms[userRole][field] = value
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(registrationUserThunk.pending, (state, action) => {
                state.error = undefined;
                state.isLoading = true;
                state.message = '';
            })
            .addCase(registrationUserThunk.fulfilled, (state, action) => {
                state.isLoading = false;
                // console.log(action.payload)
                // state.user = action.payload;
            })
            .addCase(registrationUserThunk.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
    },
})

export const { actions: registrationActions } = registrationSlice
export const { reducer: registrationReducer } = registrationSlice

