
export let serverUrl;
if(process.env.NODE_ENV === `development`){
    serverUrl = process.env.REACT_APP_API_URL_DEV
}else {
    serverUrl = process.env.REACT_APP_API_URL_PROD
}

export const apiUrl = `${serverUrl}/api`
export const imagesUrl = `${serverUrl}/images`
export const uploadUrl = `${apiUrl}/upload`

// ssh -R 80:localhost:8000 serveo.net
// tmole 8080


// http://15.237.182.239:3001/
// bitnami
// запуск react приложение на сервере
// cd carlogix
// screen -d -m nohup serve -s build -l 3001 &
// Press Ctrl-A then Ctrl-D. This will detach your screen session but leave your processes running.


// запуск nodejs API
// cd carlogixAPI
// screen -d -m nohup run start

// Остановить приложение
// screen -ls                                                -- To list the available screens
// screen -X -S [session you want to kill] quit              --To quit the session

// Удалить все файлы в папке:   rm -r node_modules

// To allow user ec2-user (Amazon AWS) write access to the public web directory (/var/www/html),
// enter this command via Putty or Terminal, as the root user sudo:
//     sudo chown -R ubuntu /var/www/html

// Make sure permissions on that entire folder were correct:
//     sudo chmod -R 755 /var/www/html

// For Ubuntu
// sudo chown -R ubuntu:ubuntu /var/www/html
// sudo chmod -R 755 /var/www/html

// sudo chown -R ubuntu /var/www/html
// sudo chown -R ubuntu /etc/nginx
// sudo chown -R ubuntu /etc/nginx/sites-available
// sudo chown -R ubuntu /etc/nginx/sites-enabled

// sudo service nginx restart
// npm install && npm install --only=dev
// npm install --include dev
// npm install
// npm install --only=dev
// rm -r node_modules

// sudo apt-get remove nodejs
// sudo apt-get remove npm
// sudo apt-get autoremove
// sudo service nginx restart
// sudo certbot certonly --nginx -d kaditrans.com

// pkill -f node
// sudo snap remove certbot
// sudo rm -r /usr/bin/certbot

// pm2 start index.js
// $ pm2 restart app_name
// $ pm2 reload app_name
// $ pm2 stop app_name
// $ pm2 delete app_name
// Instead of app_name you can pass:
// all to act on all processes
// id to act on a specific process id
// pm2
// pm2 monit


// git reflog




// location / {
// # First attempt to serve request as file, then
// # as directory, then fall back to displaying a 404.
// # try_files $uri $uri/ =404;
// proxy_pass http://localhost:3000;
//     proxy_http_version 1.1;
// proxy_set_header Upgrade $http_upgrade;
// proxy_set_header Connection 'upgrade';
// proxy_set_header Host $host;
// proxy_cache_bypass $http_upgrade;
// }

// Привет! Ты веб разработчик, тебе нужно сделать перевод приложения с рузского на другие языки.
//     Ты работаешь с react-i18next. Создай файл переводов (locales) для английского языка для этих ключей:

// Привет! Ты опытный веб-разработчик.  Создай json файл, используй эти данные как ключи,
// значения ключей равны ключам, без вложенных обьектов

// Привет! Переведи значения этого Json Объекта на следующие языки: немецкий, польский, украинский, испанский, итальянский.
// Для каждого языка создай новый объект.
// Сохрани ключи объекта на русском языке
//
// {
//     "Адрес загрузки": "Loading address",
//     "Адрес разгрузки": "Unloading address",
//     "Дата готовности к загрузке": "Loading ready date",
//     "Создано": "Created",
//     "Подробнее": "More",
//     "Резерв": "Reserve"
// }
