
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios";
import {apiUrl} from "../../../shared/API/API";

export const applyForCar = createAsyncThunk(
    'apply/applyForCar',
    async (data, thunkAPI) => {
        try {
            let id = data.car.id;
            const response = await axios.put(`${apiUrl}/cars/${id}/apply`, data)
            // console.log(response.data)
            if(!response.data) throw new Error();
            // thunkAPI.dispatch(carDetailsActions.setCar(response.data))
            return response.data
        }catch (e) {
            console.log(e);
            return thunkAPI.rejectWithValue('Ошибка на сервере при оформлении заявки')
        }
    }
)
