
import { Link, LinkProps } from 'react-router-dom';
import classNames from "../Components/shared/lib/classNames";
import cls from './AppLink.module.scss'




export const AppLink = (props) => {
    const {
        to,
        className,
        children,
        classes,
        ...otherProps
    } = props;

    return (
        <Link
            to={to}
            className={cls[className]}
            // className={`${className} ${classNames (classes)}`}
            {...otherProps}
        >
            {children}
        </Link>
    );
};
