
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios";
import {apiUrl} from "../../../shared/API/API";

export const refuseCarDelivery = createAsyncThunk(
    'refuse/refuseCarDelivery',
    async (data, thunkAPI) => {
        try {
            let id = data.car._id;
            const response = await axios.put(`${apiUrl}/cars/${id}/refuse`, data)
            console.log(response.data)
            if(response?.data?.status !== 'OK') throw new Error('Ошибка на сервере при деактивации заявки');
            return response.data
            // thunkAPI.dispatch(carDetailsActions.setCar(response.data))
        }catch (e) {
            console.log(e);
            return thunkAPI.rejectWithValue('Ошибка на сервере при отклонении заявки')
        }
    }
)
