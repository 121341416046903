
import {lazy} from 'react';

// const OrderPageAsync = lazy( ()=> new Promise((resolve) =>{
//     setTimeout( ()=> resolve(import('./OrderPage')), 500)
//     }
// ))

const EditCarPageAsync = lazy( ()=> import('./EditCarPage'))

export default EditCarPageAsync







