import { createSlice } from '@reduxjs/toolkit'
import {getNotifications} from "./services/getNotifications";


const initialState = {
    error: false,
    isLoading: true,
    notifications: [],
}

export const notificationsSlice = createSlice({
    name: 'notificationsSlice',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getNotifications.pending, (state, action) => {
                state.error = undefined;
                state.isLoading = true;
            })
            .addCase(getNotifications.fulfilled, (state, action) => {
                state.isLoading = false;
                state.notifications = action.payload;
            })
            .addCase(getNotifications.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
    },
})

export const { actions: notificationsActions } = notificationsSlice
export const { reducer: notificationsReducer } = notificationsSlice

