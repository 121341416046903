export const btnTypesColors = {
    primary: '#007bff',
    secondary: `#5A6268`,
    warning: `#ffc107`,
    danger: `#dc3545`,
    info: `#17a2b8`,
    success: '#28a745',
    dark: `#343a40`,
    dark_blue: `#0052B4`,
    orange: `#ff7000`
}
// https://getbootstrap.com/docs/4.0/components/buttons/
