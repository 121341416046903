import React from 'react';
import cls from './Footer.module.scss';
import { WhatsAppOutlined, FacebookOutlined } from '@ant-design/icons';
import {useTranslation} from "react-i18next";


const Footer = () => {
    const { t } = useTranslation('footer');
    return (
        <footer className= {cls.footer}>
            <div className={cls.container}>

                <div className= {cls.navigation}>
                    <div className= {cls.title}>{t('Клиентам')}</div>
                    <ul className= {cls.list}>
                        <li><a href="/">{t('Главная')}</a></li>
                        <li><a href="/cars">{t('Перевозки авто')}</a></li>
                        <li><a href="/order">{t('Заказать перевозку')}</a></li>
                        <li><a href="/contacts">{t('Контакты')}</a></li>
                    </ul>
                </div>

                <div className= {cls.navigation}>
                    <div className= {cls.title}>{t('Сотрудничество')}</div>
                    <ul className= {cls.list}>
                        {/*<li><a href="#">{t('Новости компании')}</a></li>*/}
                        <li><a href="/login">{t('Личный кабинет')}</a></li>
                        <li><a href="#howdeliver">{t('Как это работает?')}</a></li>
                        <li><a href="#">{t('Способы оплаты')}</a></li>
                        <li><a href="/documentation">{t('Документация для партнеров')}</a></li>
                    </ul>
                </div>

                {/*<div className= {cls.navigation}>*/}
                {/*    <div className= {cls.title}>{t('Поддержка')}</div>*/}
                {/*    <ul className= {cls.list}>*/}
                {/*        <li><a href="#">{t('Часто задаваемые вопросы')}</a></li>*/}
                {/*        <li><a href="#">{t('Способы оплаты')}</a></li>*/}
                {/*        <li><a href="#">{t('Как это работает?')}</a></li>*/}
                {/*        <li><a href="#">{t('Контакты')}</a></li>*/}
                {/*    </ul>*/}
                {/*</div>*/}

                <div className= {cls.navigation}>
                    <div className= {cls.title}>{t('Контакты')}</div>
                    <ul className= {cls.list}>
                        <li><a href="tel:+491775993233"><span  className= {cls.phone_mark}>📞</span> +49 177 599 32 33</a></li>
                        <li><a href="https://wa.me/491775993233"><span  className= {cls.phone_mark}>
                            <WhatsAppOutlined />
                            </span> +49 177 599 32 33</a>
                        </li>
                        {/*<li><a href="tel:+48731218000"><span  className= {cls.phone_mark}>📞</span> +48 731 218 000</a></li>*/}
                        <li><a href="https://wa.me/48796514268"><span  className= {cls.phone_mark}>
                            <WhatsAppOutlined />
                            </span> +48 796 514 268</a>
                        </li>
                        <li>
                            <a href="mailto:kadispzoo@gmail.com">
                                <span  className= {cls.email_mark}>📨</span>kadispzoo@gmail.com<
                            /a>
                        </li>
                        <li><a href="https://www.facebook.com/Kaditrans"><span  className= {cls.facebook_mark}>
                            <FacebookOutlined /></span></a>
                        </li>
                    </ul>
                </div>


                {/*<div className="logo">*/}
                    {/*<a href="#"><img src={logo} alt=""/></a>*/}
                {/*</div>*/}
            </div>
        </footer>
    );
};

export default Footer;
