import React from 'react';
import cls from './NotFoundPage.module.scss';

export const NotFoundPage = () => {
    return (
        <div className={cls.NotFoundPage}>
            <div className={cls.container}>
                <div className={cls.wrapper}>
                    <h1 className={cls.error}>404</h1>
                    <div className={cls.page}>Ooops!!! The page you are looking for is not found</div>
                    <a className={cls.back_home} href="/">Back to home</a>
                </div>

            </div>
        </div>
    );
};












