
import { createSlice } from '@reduxjs/toolkit'


const initialState = {
    mapApiIsLoaded: false,
    loadError: null,

    error: false,
    isLoading: false,
    message: '',
}

export const googleMapJsApiLoaderSlice = createSlice({
    name: 'googleMapJsApiLoader',
    initialState,
    reducers: {
        setMapApiIsLoaded: (state, action)=>{
            state.mapApiIsLoaded = action.payload
        },
        setMessage: (state, action)=>{
            console.log(action.payload)
            state.message = true;
        }

    },
    // extraReducers: (builder) => {
    //     builder
    //         .addCase(getCar.pending, (state, action) => {
    //             state.error = undefined;
    //             state.isLoading = true;
    //         })
    //         .addCase(getCar.fulfilled, (state, action) => {
    //             state.isLoading = false;
    //         })
    //         .addCase(getCar.rejected, (state, action) => {
    //             state.isLoading = false;
    //             state.error = action.payload;
    //         })
    // },
})

export const { actions: googleMapJsApiLoaderActions } = googleMapJsApiLoaderSlice
export const { reducer: googleMapJsApiLoaderReducer } = googleMapJsApiLoaderSlice

