
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios";
import {userActions} from "../userSlice";
import {apiUrl} from "../../../shared/API/API";

export const fetchUserOrders = createAsyncThunk(
    'user/fetchUserOrders',
    async (data, thunkAPI) => {
        try {
            let {params={}} = data;
            console.log(params)
            const response = await axios.get(`${apiUrl}/cars`, { params });

            if(!response.data) throw new Error();
            thunkAPI.dispatch(userActions.setUserCars(response.data))
            return response.data
        }catch (e) {
            console.log(e);
            return thunkAPI.rejectWithValue('Ошибка при получении пользовательских автомобилей')
        }
    }
)
