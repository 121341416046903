import React from 'react';
import { Flex, Spin } from 'antd';
import cls from './SpinLoader.module.scss'

const SpinLoader = () => (
    <div className={cls.container}>
        <Flex align="center" gap="middle">
            <Spin size="large" />
        </Flex>
    </div>

);
export default SpinLoader;
