import { configureStore } from '@reduxjs/toolkit'
import {counterReducer} from "../Components/Counter/counterSlice";
import {carsReducer} from "../Components/CarsPage/model/carsSlice";
import {orderFormReducer} from "../Components/OrderPage/AddCarForm/model/orderFormSlice";
import {carDetailsReducer} from "../Components/CarDetailsPage/model/carDetailsSlice";
import {googleMapJsApiLoaderReducer} from "../Components/GoogleMapJsApiLoader/model/GoogleMapJsApiLoaderSlice";
import {userReducer} from "../Components/UserPage/model/userSlice";
import {loginReducer} from "../Components/LoginPage/model/loginSlice";
import {userCarsReducer} from "../Components/UserPage/RolePages/model/userCarsSlice";
import {registrationReducer} from "../Components/RegistrationPage/model/registrationSlice";
import {notificationsReducer} from "../Components/UserPage/shared/NotificationPage/model/NotificationsSlice";
import {
    deliveriesSlice, deliveriesSliceReducer
} from "../Components/UserPage/RolePages/CarrierRolePage/CurrierDeliveriesPage/model/deliveriesSlice";
import {searchCarsFormReducer} from "../Components/CarsPage/SearchCarsForm/model/searchCarsFormSlice";

export function createReduxStore(initialSchema){
    return configureStore({
        reducer: {
            counter: counterReducer,
            cars: carsReducer,
            orderForm: orderFormReducer,
            carDetails: carDetailsReducer,
            user: userReducer,
            login: loginReducer,
            registration: registrationReducer,
            googleMapJsApiLoader: googleMapJsApiLoaderReducer,
            userCars: userCarsReducer,
            notifications: notificationsReducer,
            deliveriesSlice: deliveriesSliceReducer,
            searchForm: searchCarsFormReducer,
        },
        preloadedState: initialSchema,
    })
}
