import React from 'react';
import cls from './Text.module.scss'
import classNames from "../lib/classNames";

export const TextTheme = {
    PRIMARY: 'primary',
    ERROR: 'error',
    INFO: 'info'
}

export const Text = ({text, title, className, theme=TextTheme.PRIMARY}) => {

    return (
        <div className={  classNames([  cls[theme] ])  }>
            {title && <p className={cls.title}>{title}</p>}
            {text && <p className={cls.text}>{text}</p>}
        </div>
    );
};


