import { createSlice } from '@reduxjs/toolkit'
import {fetchCars} from "./services/getCarsFromServer";

const initialState = {
    cars: [],
    error: false,
    isLoading: true,
}

export const carsSlice = createSlice({
    name: 'cars',
    initialState,
    reducers: {
        getCars: (state, action)=> {
            state.cars = action.payload
        },
        setCarsData: (state, action)=> {
            state.cars = action.payload.cars
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCars.pending, (state, action) => {
                state.error = undefined;
                state.isLoading = true;
            })
            .addCase(fetchCars.fulfilled, (state, action) => {
                state.isLoading = false;
                state.cars = action.payload;
            })
            .addCase(fetchCars.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
    },
})

export const { actions: carsActions } = carsSlice
export const { reducer: carsReducer } = carsSlice

