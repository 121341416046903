import React, {useEffect, useState} from 'react';
import cls from './CarDetailsPage.module.scss'
import ForPartners from "../MainPage/ForPartners/ForPartners";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {getCar} from "./model/services/getCarFromServer";
import {AppLink} from "../../router/AppLink";
import Button from "../shared/Button/Button";
import MapComponent from "../shared/MapComponent/MapComponent";

import {PageLoader} from "../shared/PageLoader/PageLoader";
import {dateFormat} from "../shared/lib/dateFormat";
import {userRoles} from "../UserPage/UserPage";
import ApplyPage from "./ApplyPage/ApplyPage";
import RefuseDeliveryPage from "./ApplyPage/RefuseDeliveryPage";
import {userActions} from "../UserPage/model/userSlice";
import {Alert} from "antd";
import {CarStatus} from "../shared/Types/CarStatus";
import DetailItem from "./DetailItem/DetailItem";
import no_photo from  '../../images/no_photo.jpg';
import {imagesUrl} from "../shared/API/API";
import {useTranslation} from "react-i18next";


export const btnModalTypes = {
    PRIMARY: 'primary',
    WARNING: `warning`,
    DEFAULT: 'default',
    SUCCESS: 'success',
    INFO: 'info',
    DANGER: 'danger',
    DARK: 'dark',
    LIGHTDARK: 'lightdark',

}
const CarDetailsPage = () => {
    const { t } = useTranslation('CarDetails');

    let { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {car, isLoading, error} = useSelector((state)=> state.carDetails)
    const {user} = useSelector(state => state.user)
    // console.log(car)

    useEffect(() => {
        dispatch(getCar( {id, user} ))
    }, [id]);

    const refresh = ()=>{
        dispatch(getCar( {id, user} ))
    }

    const carImage = ()=>{
        if(!car?.car_image) return no_photo;
        const imageUrl = `${imagesUrl}/${car.car_image}`
        return imageUrl;
    }
    let locations = {origin:  car.downloading_address, destination: car.unloading_address};
    let isUserCarrier = (user)=> user && user.role === userRoles.CARRIER;
    let isShowApplyPage = (user, car) => isUserCarrier(user) && car.status === CarStatus.ACTIVE;
    let isShowRefuseDeliveryPage  = (user, car) => user?._id === car.onDeliveryBy;
    let isShowUnactivatedCarrierMessage = (user)=> isUserCarrier(user) && user.status === `created`;

    const handleNewRegistration = ()=>{
        dispatch(userActions.logout())
        navigate("/registration");
    }
    return (
        <>
          {isLoading && <PageLoader />}
          {!isLoading && <div className={cls.carDetailsPage}>

                <div className={cls.container}>

                    <div  className={cls.submitbtn}>

                        {isShowUnactivatedCarrierMessage(user) &&
                            <Alert message= {
                                `
                                ${t('Администратор должен активировать вашу учетную запись.')}
                                ${t('Выйдите и еще раз войдите в свою учетную запись, что бы обновить данные.')}
                                `
                            }
                            type={'info'}/>
                        }

                        {isShowApplyPage(user, car) &&
                            <ApplyPage car={car} user={user} refresh={refresh}
                                       btnType={btnModalTypes.PRIMARY}
                            />
                        }
                        {isShowRefuseDeliveryPage(user, car) &&
                            <RefuseDeliveryPage
                                car={car} user={user} refresh={refresh}
                                btnType={btnModalTypes.WARNING}
                            />
                        }
                        { !isUserCarrier(user) &&
                            <AppLink className={''} to={`/registration`}>
                                <Button classes={['btn_info']} onClick={handleNewRegistration} >
                                    {t('Зарегестрируйтесь как перевозчик')}
                                </Button>
                            </AppLink>
                        }
                    </div>

                    <div className={cls.detailsItem}>
                        <DetailItem title={ `${t('Детали о заявке')}: ${car._id}`}/>
                    </div>

                    <div className={cls.detailsItem}>
                        <DetailItem title={`${t('Общая информация')}`}
                                    infoTitle={`${t('Статус')}`}
                                    className={`${cls[car.status]} ${cls.textBold}`}
                                    infoData={car.status}
                        />
                        <DetailItem
                            infoTitle={`${t('Дата создания')}`}
                            className={cls.infoData}
                            infoData={dateFormat(car.createdAt)}
                        />
                    </div>

                    <div className={cls.detailsItem}>
                        <DetailItem title={`${t('Дата и место загрузки')}`}
                                    infoTitle={`${t('Место загрузки')}`}
                                    className={`${cls[car.status]} ${cls.textBold}`}
                                    infoData={car.downloading_address}
                        />
                        <DetailItem
                            infoTitle={`${t('Дата готовности к загрузке')}`}
                            className={cls.infoData}
                            infoData={car.downloading_data}
                        />
                    </div>

                    <div className={cls.detailsItem}>
                        <DetailItem title={`${t('Место выгрузки')}`}
                                    infoTitle={`${t('Место выгрузки')}`}
                                    infoData={car.unloading_address}
                        />
                    </div>

                    <div className={cls.detailsItem}>
                        <DetailItem title={`${t('Данные автомобиля')}`}
                                    infoTitle={`${t('Модель')}`}
                                    infoData={`${car.car_brand} ${car.car_model}`}
                        />
                        <DetailItem
                                    infoTitle={`${t('Состояние')}`}
                                    infoData={car.car_condition}
                        />
                    </div>

                    <div className={cls.detailsItem}>
                        <DetailItem title={`${t('Цена за перевозку')}`}
                                    infoTitle={`${t('Цена за перевозку')}`}
                                    infoData={`€ ${car.car_delivery_price}`}
                                    className={cls.textBold}
                        />
                    </div>

                    <div className={cls.detailsItem}>
                        <DetailItem title={`${t('Дополнительная информация')}`}
                                    infoTitle={`${t('')}`}
                                    infoData={car.additional_information}
                                    className={cls.textBold}
                        />
                    </div>

                    <div className={cls.detailsItem}>
                        <div className={cls.detailsTitle}>{`${t('Фото автомобиля')}`}</div>
                        <div className={cls.detailsInfo}>
                            <div  className={cls.carimage}>
                                <img src={carImage()} alt= {`photo car ${car.id}`} />
                            </div>
                        </div>
                    </div>

                    <div className={cls.detailsItem}>
                        <div className={cls.detailsTitle}>{`${t('Карта маршрута')}`}</div>
                        <div className={cls.detailsInfo}>
                            {locations.origin && <MapComponent locations={locations} />}
                        </div>
                    </div>


                </div>
            </div>}
        </>

    );
};

export default CarDetailsPage;
