import React from 'react';
import { Button, Result } from 'antd';
import {AppLink} from "../../../../router/AppLink";

const RegistrationSuccessPage = (id) => {
    return (
        <Result
            status="success"
            title="Спасибо за регистрацию!"
            subTitle="На нашем сайте вы можете создавать заказы на перевозку машин и выполнять услуги по доставке"
            extra={[
                <AppLink to="/" key={1}>
                    <Button  as={<AppLink/>} classes={['btn_info']} >На главную</Button>
                </AppLink>,
                <AppLink to={`/user`} key={2}>
                    <Button  as={<AppLink/>} classes={['btn_info']} >Мои перевозки</Button>
                </AppLink>
            ]}
        />
    );
};

export default RegistrationSuccessPage;
