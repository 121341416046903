import React, {useEffect, useState} from 'react';
import cls from './header.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {userActions} from "../UserPage/model/userSlice";
import {Button, Drawer, Space} from "antd";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";
import HeaderMenu from "./HeaderMenu";
import logo from "../../images/logo_car_v2.png";
import kadiLogo from "../../images/Logo_Kadi.png"


const Header = () => {

    const dispatch = useDispatch();
    const {user} = useSelector(state=> state.user)

    useEffect(()=>{
        dispatch(userActions.initUserData())
    }, [])

    const handleLogout = ()=>{
        dispatch(userActions.logout())
    }

    const [open, setOpen] = useState(false);
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    return (
        <header className= {cls.header}>
            <div className={cls.container}>

            <div className={cls.logo}>
                <div className={cls.logo_title}>
                    <a href="/">
                        <img src={kadiLogo} alt="logo image"/>
                        <span>kaditrans</span>
                    </a>
                </div>
                {/*<a href="/"><img src={logo} alt="logo image"/></a>*/}
            </div>

            <div className={cls.menu_inline}>
                <HeaderMenu user={user} handleLogout={handleLogout} />
            </div>

            <div className={cls.menu_burger}>
                <Button className="btn" onClick={showDrawer}>
                    <MenuOutlined />
                </Button>
                <Drawer classNames={cls.drawer}
                    title="KADITRANS"
                    placement="right"
                    closable={false}
                    onClose={onClose}
                    open={open}
                        extra={
                            <Space>
                                <Button onClick={onClose}><CloseOutlined /></Button>
                            </Space>
                        }
                >
                    <HeaderMenu user={user} handleLogout={handleLogout} onClose={onClose}/>
                </Drawer>
            </div>

            </div>

        </header>
    );
};

export default Header;
