import { createSlice } from '@reduxjs/toolkit'
import {getUserDeliveries} from "./services/getUserDeliveries";


const initialState = {
    error: false,
    isLoading: true,
    myDeliveries: []
}

export const deliveriesSlice = createSlice({
    name: 'deliveriesSlice',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserDeliveries.pending, (state, action) => {
                state.error = undefined;
                state.isLoading = true;
            })
            .addCase(getUserDeliveries.fulfilled, (state, action) => {
                state.isLoading = false;
                state.myDeliveries = action.payload;
            })
            .addCase(getUserDeliveries.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
    },
})

export const { actions: deliveriesSliceActions } = deliveriesSlice
export const { reducer: deliveriesSliceReducer } = deliveriesSlice

