
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios";
import {carsActions} from "../carsSlice";
import {apiUrl} from "../../../shared/API/API";

export const fetchCars = createAsyncThunk(
    'cars/fetchCars',
    async (data, thunkAPI) => {
        try {
            let params = {};
            if(data && data.params) params = {...data.params};
            // console.log(params)
            const response = await axios.get(`${apiUrl}/cars`, {params})

            if(response?.data?.status === 'OK'){
                const cars = response.data.data
                // thunkAPI.dispatch(carsActions.setCarsData(cars))
                return cars
            }
            throw new Error('Ошибка на сервере при полученни автомобилей')
        }catch (e) {
            console.log(e);
            return thunkAPI.rejectWithValue('Ошибка на сервере при полученни автомобилей')
        }
    }
)
