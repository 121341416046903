
import {lazy} from 'react';

// const OrderPageAsync = lazy( ()=> new Promise((resolve) =>{
//     setTimeout( ()=> resolve(import('./OrderPage')), 500)
//     }
// ))

const EditUserPageAsync = lazy( ()=> import('./EditUserPage'))

export default EditUserPageAsync







