import React from 'react';
import  './Button.css';
import classNames from "../lib/classNames";

export const BtnTypes = {
  BUTTON: 'button',
  SUBMIT: 'submit'
}

const Button = ({classes, onClick, children, type=BtnTypes.BUTTON}) => {
    // console.log(typeof onClick)
    return (
        <button className={`button ${classNames (classes)}`} onClick={onClick}
                type={type}
        >
            {children}
        </button>
    );
};

export default Button;
